<template>
  <div class="class-card">
    <div class="school">
      <div class="school-checkbox-part">
        <van-checkbox v-if="checkboxShow" v-model="checkSchool" @change="toggleAllBuilding()" shape="square" name="a"
          icon-size="18px">
        </van-checkbox>
        <div class="school-label">校园</div>
      </div>
      <div style="display:flex">
        <div v-if="!allOpenFlag" class="switch" style="margin-right: 20px;" @click="allOpen">
          全部展开</div>
        <div v-else class="switch" style="margin-right: 20px;" @click="allClose">
          全部折叠</div>
        <div style="display:flex" v-if="status != 'offline' && sceneList.length > 0">
          <div class="switch" v-if="!checkboxShow" @click="batchSwitch" key="close" v-has="'mobile.scene.batchControl'">
            批量切换场景</div>
          <div class="cancel-group" v-else key="open">
            <div class="switch" @click="batchSwitch">取消操作</div>
            <div class="switch" @click="switchScene">切换场景</div>
          </div>
        </div>
      </div>
    </div>
    <van-collapse v-model="activeNames">
      <van-collapse-item title="标题1" :name="index" v-for="item, index in sceneList" :key="index">
        <template #title>
          <div class="conllapse-title-part">
            <div class="conllapse-title-checkbox" @click.stop="toggleAllClass(index, item)">
              <van-checkbox v-if="checkboxShow" @change="buildingChange(index, item)" v-model="building[index]"
                shape="square" name="a" icon-size="17px">
              </van-checkbox>
            </div>
            <div>
              <span class="conllapse-title">{{ item.name }}</span>
              <span class="conllapse-title" v-if="status != 'online'"> 离线教室：</span>
              <span class="conllapse-title" v-if="status != 'online'">{{ item.offline }}</span>
            </div>
          </div>
        </template>
        <van-checkbox-group v-model="result[index]" ref="checkboxGroup" icon-size="16px"
          @change="groupChange(item, index)">
          <div class="conllapse-class-item" v-for="classItem, classIndex in item.list" :key="classIndex">
            <van-checkbox v-if="checkboxShow" :disabled="classItem.connected == 0||!classItem.connected" shape="square"
              :name="classItem.nodeEui">
            </van-checkbox>
            <class-item :classItem="classItem"></class-item>
          </div>
        </van-checkbox-group>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import { getScene } from '../../../http/control/control'
import ClassItem from './ClassItem.vue'
export default {
  components: { ClassItem },
  props: {
    sceneList: Array,
    status: String
  },
  data() {
    return {
      activeNames: [],
      checkSchool: false,
      checkboxShow: false,
      allOpenFlag: false,
      house: [],
      result: [],
      building: [],
      toggleBuilding: false,
      buildingChangeFlag: false,

    }
  },
  methods: {
    allOpen() {
      this.activeNames = []
      for (let i in this.sceneList) {
        this.activeNames.push(Number(i))
      }
      this.allOpenFlag = true
    },
    allClose() {
      this.activeNames = []
      this.allOpenFlag = false
    },
    groupChange(item, index) {
      if (this.result[index].length + item.offline == item.list.length) {
        this.building[index] = true
      }
      else {
        this.building[index] = false
      }
      this.$forceUpdate();
    },
    toggleAllBuilding() {
      if (this.buildingChangeFlag) {
        this.buildingChangeFlag = false
        return
      }
      if (this.checkSchool) {
        for (let i in this.sceneList) {
          this.building[i] = true
        }
      }
      else {
        for (let i in this.sceneList) {
          this.building[i] = false
        }
      }
      this.toggleBuilding = true
      this.$forceUpdate();
    },
    buildingChange(index, item) {
      if (this.toggleBuilding == true) {
        if (index == this.sceneList.length - 1) {
          this.toggleBuilding = false
        }
        this.result[index] = []
        if (this.building[index] == undefined || this.building[index] == false) {
          this.$refs.checkboxGroup[index].toggleAll(false);
        }
        else {
          for (let i of item.list) {
            if (i.connected == 1) {
              this.result[index].push(i.nodeEui)
            }
          }
          this.$refs.checkboxGroup[index].toggleAll({
            checked: true,
            skipDisabled: true,
          });
        }
      } else {
        if (this.building.length == this.sceneList.length && !this.building.includes(false)) {
          this.checkSchool = true
          this.buildingChangeFlag = true
        }
        else {
          this.checkSchool = false
          this.buildingChangeFlag = true
        }
        this.$forceUpdate();
      }
    },
    batchSwitch() {
      this.result = []
      this.building = []
      this.checkSchool = false
      this.checkboxShow = !this.checkboxShow
    },
    toggleAllClass(index, item) {
      this.result[index] = []
      if (this.building[index] == undefined || this.building[index] == false) {
        for (let i of item.list) {
          if (i.connected == 1) {
            this.result[index].push(i.nodeEui)
          }
        }
        this.$refs.checkboxGroup[index].toggleAll({
          checked: true,
          skipDisabled: true,
        });
      }
      else {
        this.$refs.checkboxGroup[index].toggleAll(false);
      }
    },
    async switchScene() {
      if (this.result.length == 0) {
        this.$toast.fail('选择的教室不能为空')
        return
      }
      let nodeEuis = JSON.parse(JSON.stringify(this.result))
      await getScene({
        nodeEuis: nodeEuis.flat().join(','),
        schoolId: this.$store.state.schoolId
      }).then((res) => {
        this.$store.commit('switchScene', res.data)
      })

    }
  },
  created() {
  },
  updated() {

  }
}
</script>

<style lang="less" scoped>
.class-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 0 18px;
}

.school {
  line-height: 48px;
  display: flex;
  justify-content: space-between;

  .school-checkbox-part {
    display: flex;
  }

  .school-label {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-left: 8px;
  }

  .switch {
    font-size: 14px;
    color: #38ADFF;
    cursor: pointer;
  }

  .cancel-group {
    display: flex;

    :first-child {
      padding-right: 16px;
    }
  }
}

.conllapse-title-part {
  display: flex;
  align-items: center;

  .conllapse-title-checkbox {
    margin-right: 8px;
  }
}

.conllapse-title {
  font-size: 14px;
  font-weight: 400;
  color: #666666;

}

.conllapse-title:first-of-type {
  font-weight: 500;
}

/deep/ .van-divider {
  margin: 0;
  border-color: #E4E4E4
}

/deep/ .van-collapse-item__title {
  padding: 0;
  line-height: 50px;
  display: flex;
  align-items: center;
  background-color: #fff;


}

/deep/ .van-collapse-item__title--expanded::after {
  display: none;
}

/deep/ .van-collapse-item__content {
  padding: 0;
}

/deep/ .van-collapse-item--border::after {
  right: 0;
  left: 0;
}

/deep/ .van-cell__right-icon {
  height: 50px;
  line-height: 50px;
}

.conllapse-class-item {
  height: 38px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}
</style>