<template>
  <div>
    <scene-control ref="sceneControl" v-if="active == 1">
    </scene-control>
    <repair-list ref="repairList" v-else :activeTabber="active"></repair-list>
    <van-tabbar v-model="active" placeholder>
      <van-tabbar-item v-has="'mobile.repair'">
        <span>报修</span>
        <template #icon="props">
          <img :src="props.active ? icon[0].active : icon[0].inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click='title' v-has="'mobile.scene.list'">
        <span>控制</span>
        <template #icon="props">
          <img :src="props.active ? icon[1].active : icon[1].inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-dialog v-model="showScene" @open="openSceneDialog" show-cancel-button @confirm="confirm"
      confirm-button-color="#378BF7" cancel-button-color="#333333">
      <div class="dialog-group">
        <div class="dialog-title">场景切换</div>
        <div class="scene-row">
          <div class="scene-item" v-for="item, index  in switchSceneList" :key="index" @click="selectScene(index)">
            <img v-if="selectedScene == index" class="selected" src="../../assets/image/icon_selected.png" alt="">
            <div class="scene-item-name">{{ index }}</div>
          </div>
          <!-- <div class="scene-item" @click="selectScene('上课模式')">
            <img v-if="selectedScene == '上课模式'" class="selected" src="../../assets/image/icon_selected.png" alt="">
            <img class="scene-item-img" src="../../assets/image/moshi1.png" alt="">
            <div class="scene-item-name">上课</div>
          </div>
          <div class="scene-item" @click="selectScene('放学模式')">
            <img v-if="selectedScene == '放学模式'" class="selected" src="../../assets/image/icon_selected.png" alt="">
            <img class="scene-item-img" src="../../assets/image/moshi2.png" alt="">
            <div class="scene-item-name">放学</div>
          </div>
          <div class="scene-item" @click="selectScene('休息模式')">
            <img v-if="selectedScene == '休息模式'" class="selected" src="../../assets/image/icon_selected.png" alt="">
            <img class="scene-item-img" src="../../assets/image/moshi3.png" alt="">
            <div class="scene-item-name">休息</div>
          </div>
          <div class="scene-item" @click="selectScene('自习模式')">
            <img v-if="selectedScene == '自习模式'" class="selected" src="../../assets/image/icon_selected.png" alt="">
            <img class="scene-item-img" src="../../assets/image/moshi4.png" alt="">
            <div class="scene-item-name">自习</div>
          </div> -->
        </div>
      </div>
    </van-dialog>
    <van-dialog v-model="showOffline" title="教室离线" show-cancel-button confirm-button-color="#378BF7"
      cancel-button-color="#333333">
      <div class="offline-tip">该教室网关已离线，暂时无法远程控制。请稍后再试，如长时间离线，可以进行报修申请。</div>
    </van-dialog>
    <van-dialog v-model="showRepairFinish" title="维修完成" show-cancel-button confirm-button-color="#378BF7"
      cancel-button-color="#333333" :before-close="finishRepair">
      <div class="finish-dialog">
        <div class="finish-dialog-label">维修原因</div>
        <div class="finish-dialog-reason-tip-group">
          <div @click="changeReason('未检出故障')" class="finish-dialog-reason-tip"
            :class="repairType == '未检出故障' ? 'finish-dialog-reason-tip-active' : ''">未检出故障</div>
          <div @click="changeReason('电路问题')" class="finish-dialog-reason-tip"
            :class="repairType == '电路问题' ? 'finish-dialog-reason-tip-active' : ''">电路问题</div>
          <div @click="changeReason('其他')" class="finish-dialog-reason-tip"
            :class="repairType == '其他' ? 'finish-dialog-reason-tip-active' : ''">其他</div>
        </div>
        <van-field v-model="repairDescription" rows="3" type="textarea" maxlength="200" placeholder="请输入留言"
          show-word-limit />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import SceneControl from "./sceneControl/index";
import RepairList from "./repairList/index";
import { finishRepair } from "../../http/repair/repair";
import { throttle } from '../../util';
import { scene } from "../../http/control/control";
export default {
  components: { SceneControl, RepairList },
  data() {
    return {
      active: 0,
      repairType: '未检出故障',
      selectedScene: '',
      repairDescription: '',
      switchSceneList: '',
      icon: [
        {
          active: require('../../assets/image/icon_wx_pre.png'),
          inactive: require('../../assets/image/icon_wx_-defaul.png'),
        },
        {
          active: require('../../assets/image/icon_kz_pre.png'),
          inactive: require('../../assets/image/icon_kz_defaul.png'),
        },
      ]
    };
  }, beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      if (from.path.includes('/classDetail')) {
        vm.active = vm.$store.state.permissions.includes('mobile.repair') ? 1 : 0
      }
      else {
        vm.active = vm.$store.state.permissions.includes('mobile.repair') ? 0 : 1
      }
      next()
    });
  },
  created() {
  },
  methods: {
    async confirm() {
      await scene({ nodeEuis: this.switchSceneList[this.selectedScene], sceneType: this.selectedScene }).then((res) => {
        this.$toast.success('切换成功')
        this.$refs.sceneControl.getSceneMessage()
      })
    },
    async finishRepair(action, done) {
      if (action === "confirm") {
        if (this.repairDescription == '') {
          this.$toast.fail('维修说明不能为空')
          done(false)
          return
        }
        await finishRepair(this.$store.state.repairId, {
          repairType: this.repairType,
          repairDescription: this.repairDescription
        })
        this.repairDescription = ''
        this.repairType = '未检出故障'
        this.$refs.repairList.getRepairList()
        done()
      }
      else {
        done()
      }

    },
    selectScene(index) {
      this.selectedScene = index
    },
    title() {
      this.$route.meta.title = '场景控制';
    },
    changeReason(value) {
      this.repairType = value
    },
    openSceneDialog() {
      this.switchSceneList = this.$store.state.switchSceneList
      this.selectedScene = Object.keys(this.switchSceneList)[0]
    }
  },
  computed: {
    showScene: {
      /* return this.$store.state.showScene */
      get() {
        return this.$store.state.showScene;
      },
      set() {
        this.$store.commit('switchScene')
      }
    },
    showOffline: {
      /* return this.$store.state.showScene */
      get() {
        return this.$store.state.showOffline;
      },
      set() {
        this.$store.commit('switchOffline')
      }
    },
    showRepairFinish: {
      /* return this.$store.state.showScene */
      get() {
        return this.$store.state.showRepairFinish;
      },
      set() {
        this.$store.commit('switchRepairFinish')
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-dialog {
  width: 320px;
  background: #FFFFFF;
  border-radius: 8px;
}

/deep/ .van-field {
  width: 288px;
  height: 120px;
  background: #F3F4F6;
  border-radius: 8px;
  border: 1px solid #E4E4E4;
  margin-top: 16px;
}

.dialog-group {
  text-align: center;
  padding-top: 21px;
  max-height: 600px;
  overflow: scroll;
  box-sizing: border-box;

  .dialog-title {
    font-size: 17px;
    font-weight: 500;
    color: #000000;
    line-height: 24px;
    margin-bottom: 13px;
  }
}

.scene-row {
  margin: 0 auto;
  display: flex;

  flex-wrap: wrap;
  width: 196px;
}

.scene-item {
  width: 98px;
  height: 120px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;

  .scene-item-img {
    width: 60px;
    height: 60px;
  }

  .scene-item-name {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px #E4E4E4 solid;
    margin-top: 15px;
    font-size: 14px;
    color: #666666;
  }

  .selected {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 25px;
    right: 6px;
  }
}

.offline-tip {
  text-align: center;
  font-size: 17px;
  margin: 16px 24px 32px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 24px;
}

.finish-dialog {
  padding: 16px;
  width: 320px;
  height: 243px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;

  .finish-dialog-label {
    margin-left: 8px;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.5);
    ;
    line-height: 17px;
  }

  .finish-dialog-reason-tip-group {
    display: flex;
    text-align: center;
    margin-top: 12px;

    .finish-dialog-reason-tip {
      height: 32px;
      padding: 0 16px;
      background: #F3F4F6;
      border-radius: 16px;
      font-size: 14px;
      border-radius: 16px;
      border: 1px solid #E4E4E4;
      color: rgba(0, 0, 0, 0.65);
      box-sizing: border-box;
      line-height: 30px;
      margin-right: 10px;
    }

    .finish-dialog-reason-tip-active {
      border: 1px solid #378BF7;
      background-color: #fff;
      color: #378BF7;
    }
  }
}
</style>