<template>
  <div class="class-item">
    <div class="class-item-label" @click="$router.push({path:`/classDetail`,query:classItem})">
      <span style="text-decoration:underline">{{ classItem.floor }}-{{ classItem.classroom }}</span>
      <span class="offline" v-if="classItem.connected == 0||!classItem.connected"> 离线 </span>
      <span class="online" v-else> 在线 </span>
      <span>{{ classItem.scene ? sceneType[classItem.scene] : '' }}</span>
      <!-- <span class="online" v-if="classItem.zy === true">有人 </span>
      <span class="offline" v-else-if="classItem.zy === false">无人 </span>
      <span v-if="classItem.illuminance!==''">{{classItem.illuminance}}LX </span> -->
    </div>
    <div class="switch-btn-offline" v-if="classItem.connected == 0" v-has="'mobile.scene.control'">
      场景切换
    </div>
    <div class="switch-btn-online" @click="switchScene" v-else v-has="'mobile.scene.control'">
      场景切换
    </div>
  </div>
</template>

<script>
import { getScene} from '../../../http/control/control';

export default {
  props: {
    classItem: Object
  },
  data() {
    return {
      showScene: false,
      sceneType: {
        1: '上课模式',
        2: '放学模式',
        3: '投影模式',
        4: '自习模式'
      }
    }
  },
  methods: {
    async switchScene() {
      await getScene({
        nodeEuis: this.classItem.nodeEui,
        schoolId: this.$store.state.schoolId
      }).then((res) => {
        this.$store.commit('switchScene',  res.data)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.class-item {
  display: flex;
  justify-content: space-between;
  margin-left: 8px;
  flex: 1;
}

.class-item-label {
  font-size: 14px;
  color: #666666;
  line-height: 20px;

  .offline {
    font-size: 14px;
    color: #CCCCCC;
  }

  .online {
    font-size: 14px;
    color: #378BF7;
    line-height: 20px;
  }
}

.switch-btn-online {
  width: 65px;
  height: 20px;
  background: #53C26C;
  border-radius: 10px;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 20px;
  text-align: center;
}

.switch-btn-offline {
  width: 65px;
  height: 20px;
  background: #CCCCCC;
  border-radius: 10px;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 22px;
  text-align: center;
}
</style>