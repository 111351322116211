<template>
  <div>
    <div class="scene-control">
      <div class="statistics">
        <div class="name-block">
          <img src="../../../assets/image/icon_dw_bs.png" alt="" class="icon">
          <div class="name">{{ school }}</div>
        </div>
        <div class="loading" v-if="totalLoading">
          <van-loading size="24px" color="#fff" vertical type="spinner">加载中</van-loading>
        </div>
        <div v-else class="number-group">
          <div class="number-item">
            <div class="number">{{ sceneMessage.total }}</div>
            <div class="number-label">教室总数</div>
          </div>
          <div class="number-item"
            style="border-left:1px solid rgba(256,256,256,0.34);border-right:1px solid rgba(256,256,256,0.34);">
            <div class="number">{{ sceneMessage.online }}</div>
            <div class="number-label">在线教室</div>
          </div>
          <div class="number-item">
            <div class="number">{{ sceneMessage.offline }}</div>
            <div class="number-label">离线教室</div>
          </div>
        </div>
      </div>
    </div>
    <van-tabs v-model="active" class="class-tabs" animated swipeable>
      <van-tab title="全部教室">
        <div class="listLoading" v-if="totalLoading">
          <van-loading size="30px" vertical type="spinner">加载中</van-loading>
        </div>
        <class-card v-else status="online" :sceneList="sceneMessage.list">
        </class-card>
      </van-tab>
      <van-tab title="在线教室">
        <div class="listLoading" v-if="onlineLoading">
          <van-loading size="30px" vertical type="spinner">加载中</van-loading>
        </div>
        <class-card v-else status="online" :sceneList="onlineSceneMessage.list">
        </class-card>
      </van-tab>
      <van-tab title="离线教室">
        <div class="listLoading" v-if="offlineLoading">
          <van-loading size="30px" vertical type="spinner">加载中</van-loading>
        </div>
        <class-card v-else status="offline" :sceneList="offlineSceneMessage.list">
        </class-card>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { sceneClassroomList } from '../../../http/control/control';

import ClassCard from '../components/ClassCard.vue'
export default {
  components: { ClassCard },
  data() {
    return {
      active: '',
      checkboxShow: false,
      sceneMessage: '',
      onlineSceneMessage: '',
      offlineSceneMessage: '',
      school: '',
      totalLoading: false,
      onlineLoading: false,
      offlineLoading: false
    }
  },
  methods: {
    getSceneMessage() {
      
      this.totalLoading = true
      this.onlineLoading = true
      this.offlineLoading = true
      sceneClassroomList({
        schoolId: this.$store.state.schoolId,
      }).then((res) => {
        this.sceneMessage = res.data
      }).finally(() => {
        this.totalLoading = false
      })
      sceneClassroomList({
        schoolId: this.$store.state.schoolId,
        connected: 1
      }).then((res) => {
        this.onlineSceneMessage = res.data
      }).finally(() => {
        this.onlineLoading = false
      })
      sceneClassroomList({
        schoolId: this.$store.state.schoolId,
        connected: 0
      }).then((res) => {
        this.offlineSceneMessage = res.data
      }).finally(() => {
        this.offlineLoading = false
      })
    }
  },
  created() {
    this.$store.commit('setTitle', '场景控制')
    this.school = this.$store.state.school
    this.getSceneMessage()
  }
}
</script>

<style lang="less" scoped>
.scene-control {
  padding: 16px 12px;
}

.statistics {
  width: 100%;
  height: 139px;
  padding: 17px 23px;
  box-sizing: border-box;
  background: linear-gradient(35deg, #557AF2 0%, #38ADFF 100%);
  border-radius: 12px;

  .name-block {
    display: flex;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .name {
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
    }
  }

  .number-group {
    display: flex;
    margin-top: 26px;

    .number-item {
      text-align: center;
      flex: 1;

      .number {
        font-size: 24px;
        font-family: Avenir-Medium, Avenir;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 24px;
      }

      .number-label {
        margin-top: 7px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 20px;
      }
    }
  }

  .loading {
    margin-top: 26px;
  }
}

.class-tabs {
  background-color: transparent;
  margin-top: 24px;

  .listLoading {
    margin-top: 30px;
  }

  /deep/ .van-tab__pane-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }

  /deep/ .van-tab {
    font-size: 16px;
    color: #333333;
    line-height: 22px;
  }

  /deep/ .van-tabs__nav {
    background-color: transparent;
    width: 262px;
    margin: 0 auto;
  }

  /deep/ .van-tab--active {
    font-size: 16px;
    color: #378BF7;
    line-height: 22px;
  }

  /deep/ .van-tabs__line {
    width: 34px;
    height: 3px;
    background: #38ADFF;
  }
}
</style>