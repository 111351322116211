<template>
  <div>
    <van-tabs v-model="activeTab" line-height="3px" line-width="34px" swipeable animated sticky offset-top="46px">
      <van-tab title="待处理">
        <template #title>
          <div :class="pendingTotal > 0 ? 'tab' : ''" :style="{ '--display': 'block' }" :count="pendingTotal">待处理</div>
        </template>
        <div class="content">
          <div class="loading" v-if="pendingLoading">
            <van-loading size="50px" vertical type="spinner">加载中...</van-loading>
          </div>
          <van-empty :image="emptyImgPending" v-else-if="pendingRepairList.length == 0"
            :description="emptyDescPending" />
          <repair-card v-else v-for="item in pendingRepairList" :key="item.id" :repair="item"></repair-card>
        </div>
      </van-tab>
      <van-tab title="已处理">
        <template #title>
          <div class="tab-2" :style="{ '--display': 'block' }" count="7">已处理</div>
        </template>
        <div class="content">
          <div class="loading" v-if="handledLoading">
            <van-loading size="50px" vertical type="spinner">加载中...</van-loading>
          </div>
          <van-empty :image="emptyImghandled" v-else-if="handledRepairList.length == 0"
            :description="emptyDeschandled" />
          <repair-card v-else v-for="item in handledRepairList" :key="item.id" :repair="item"></repair-card>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getRepairList } from '../../../http/repair/repair'
import { debounce, throttle } from '../../../util'
import RepairCard from '../components/RepairCard.vue'
export default {
  props: {
    activeTabber: Number
  },
  components: { RepairCard },
  data() {
    return {
      activeTab: '',
      pendingRepairList: [],
      handledRepairList: [],
      pendingTotal: '',
      schoolId: '',
      pendingPageSize: 10,
      pendingPage: 1,
      handledPageSize: 10,
      handledPage: 1,
      handledTotal: '',
      handledLoading: false,
      pendingLoading: false,
      emptyDeschandled: '',
      emptyDescPending: '',
      emptyImghandled: '',
      emptyImgPending: ''
    }
  },
  methods: {
    getRepairList() {
      this.pendingLoading = true
      this.handledLoading = true
      getRepairList({
        schoolId: this.$store.state.schoolId,
        page: this.pendingPage,
        pageSize: this.pendingPageSize,
        status: 1
      }).then((res) => {
        this.pendingTotal = res.data.total
        this.pendingRepairList = res.data.data
        this.emptyDescPending = '无记录'
        this.emptyImgPending = 'default'
      }).catch((err) => {
        this.emptyDescPending = '网络异常'
        this.emptyImgPending = 'network'
      }).finally(() => {
        this.pendingLoading = false
      })
      getRepairList({
        schoolId: this.$store.state.schoolId,
        page: this.handledPage,
        pageSize: this.handledPageSize,
        status: 2
      }).then((res) => {
        this.emptyDeschandled = '无记录'
        this.emptyImghandled = 'default'
        this.handledTotal = res.data.total
        this.handledRepairList = res.data.data
      }).catch((err) => {
        this.emptyDeschandled = '网络异常'
        this.emptyImghandled = 'network'
      }).finally(() => {
        this.handledLoading = false
      })
    },
    async addRepairList() {
      if (this.activeTab == 0) {
        if (this.pendingRepairList.length >= this.pendingTotal) {
          return
        }
        this.pendingPage++;
        const res = await getRepairList({
          schoolId: this.$store.state.schoolId,
          page: this.pendingPage,
          pageSize: this.pendingPageSize,
          status: 1
        })
        this.pendingRepairList = this.pendingRepairList.concat(res.data.data)
      }
      else {
        if (this.handledRepairList.length >= this.handledTotal) {
          return
        }
        this.handledPage++;
        const res = await getRepairList({
          schoolId: this.$store.state.schoolId,
          page: this.handledPage,
          pageSize: this.handledPageSize,
          status: 2
        })
        this.handledRepairList = this.handledRepairList.concat(res.data.data)
      }
    }
  },
  created() {
    window.onscroll = throttle(() => {
      if (this.activeTabber == 1) {
        return
      }
      let visionHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      let scrolledHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let trueHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      if (visionHeight + scrolledHeight == trueHeight) {
        this.addRepairList()
      }
    }, 300)
    this.$store.commit('setTitle', '报修列表')
    this.getRepairList()
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  background: #38ADFF;
}

/deep/ .van-tabs {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}

/deep/ .van-tabs__wrap {
  height: 48px;
  background: #FFFFFF;
}


/deep/ .van-tab {
  flex: none;
  padding: 27px;


}

.tab::after {
  content: attr(count);
  padding: 0 5px;
  max-width: 25px;
  height: 14px;
  background: #F15642;
  border-radius: 7px;
  position: absolute;
  display: var(--display);
  top: 20px;
  left: 73px;
  text-align: center;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 14px;
}

.content {
  margin: 12px;
}

.loading {
  margin-top: 200px;
}

/deep/ .van-tabs__nav {
  justify-content: center;
}
</style>