<template>
  <div class="repair-card">
    <div class="card-content">
      <div class="nav">
        <div class="building">
          <img class="logo" src="../../../assets/image/icon_dw.png" alt="">
          <div class="building-No">
            {{ repair.location }}
          </div>
        </div>
        <div class="status-repaired" v-if="repair.status == 2">
          已维修
        </div>
      </div>
      <div class="item-label">维修单号：<span class="item-value">{{ repair.serialNo }}</span></div>
      <div class="item-label device">
        <div class="device-label">故障设备：</div>
        <div><span class="item-value" v-for="item, index in repair.devices" :key="index">{{ item.name }}({{ item.value
        }}) </span>
        </div>
      </div>
      <div class="contact">
        <div class="item-label">报修人：<span class="item-value">{{ repair.manager.name }}</span></div>
        <div class="item-label"  v-if="repair.phone">联系方式：<span class="item-value">{{ repair.phone }}</span></div>
      </div>
    </div>
    <van-divider />
    <div class="card-footer">
      <div class="time">
        {{ repair.updatedAt }}
      </div>
      <div class="btn-group">
        <div class="detail-btn" @click="detail(repair.id)" v-has="'mobile.repair.show'">详情</div>
        <div class="finish-btn" @click="finish" v-if="repair.status == 1" v-has="'mobile.repair.update'">维修完成</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    repair: Object
  },
  methods: {
    finish() {
      this.$store.commit('switchRepairFinish',this.repair.id)
    },
    detail(id) {
      this.$router.push(`/detail/${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.repair-card {
  background: #FFFFFF;
  border-radius: 12px;
  padding-top: 10px;
  margin-top: 12px;
  font-family: PingFangSC-Regular, PingFang SC !important;
}

.card-content {
  padding-left: 16px;
  padding-right: 16px;
}

.nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
}

.building {
  display: flex;
  padding-top: 6px;

  .logo {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .building-No {
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    line-height: 14px;
  }
}

.item-label {
  font-size: 14px;
  color: #999990;
  line-height: 20px;
  margin-top: 6px;
}

.device {
  display: flex
}

.device-label {
  flex-shrink: 0
}

.item-value {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
}

.status-repaired {
  width: 64px;
  height: 24px;
  background: #01D079;
  border-radius: 12px;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 24px;
  text-align: center;
}

.contact {
  display: flex;
  justify-content: space-between;
}

/deep/ .van-divider {
  margin-top: 21px;
  margin-bottom: 0;
}

.card-footer {
  height: 43px;
  line-height: 43px;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 20px;

  .time {
    font-size: 12px;
    color: #999999;
  }

  .btn-group {
    color: #38ADFF;
    font-size: 14px;
    display: flex;

    .detail-btn {}

    .finish-btn {
      margin-left: 40px;
    }
  }
}
</style>